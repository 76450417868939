<template>
	<div v-if="isshowgrid">
		<va-card title="Interface List">
			<div class="row align--center">
				<div class="flex xs12 md6">
					<va-input
						:value="term"
						placeholder="search"
						@input="search"
						removable
					>
					<va-icon name="fa fa-search" slot="prepend" />
					</va-input>
				</div>
			</div>
			<va-data-table
				:fields="fields"
				:data="filteredData"
				:per-page="parseInt(perPage)"
				clickable
			>
			<template slot="status" slot-scope="props">
				<va-badge
		          v-if="(props.rowData.speed==0)"
		          class="ma-0 badge badge_status"
		          color="red"
		          :disabled="true">
          		</va-badge>
          		<va-badge
		          v-if="(props.rowData.speed >= 1000)"
		          class="ma-0 badge badge_status"
		          color="green"
		          :disabled="true">
          		</va-badge>
          		<va-badge
		          v-if="((props.rowData.speed < 1000) && (props.rowData.speed != 0))"
		          class="ma-0 badge badge_status"
		          color="orange"
		          :disabled="true">
          		</va-badge>
			</template>
			<template slot="actions" slot-scope="props">
				<va-button flat small color="gray" v-if="(props.rowData.speed >= 1000 && props.rowData.type != 'Management')" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
			</template>
			</va-data-table>
		</va-card>
	</div>
	<div v-else-if='isshowForm'>
		<div>
			<va-card :title="title">
				<template slot="actions">
					<va-button color="gray" @click="list()">List</va-button>
				</template>
				<div>
					<form>
						<va-input
							v-model="interface_name"
							type="text"
							label="Interface Name"
							placeholder="Enter Interface Name"
							:error="!!interfaceNameErrors.length"
							:error-messages="interfaceNameErrors"
							:disabled="true"
						/>
						<va-select
			              label="Type*"
			              placeholder="Select Interface Type"
			              v-model="type"
			              textBy="id"
			              :options="interfaceTypeOptions"
			              :error="!!interfaceTypeErrors.length"
			              :error-messages="interfaceTypeErrors"
			              @input="checkInterfaceType()"
			              noClear
			            />
						<va-input
							v-model="unicast_ip"
							pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
							type="text"
							label="Unicast IP*"
							placeholder="Enter Unicast IP"
							:error="!!unicastipErrors.length"
							:error-messages="unicastipErrors"
						/>
						<va-select
			              label="Unicast Netmask*"
			              placeholder="Select Unicast Netmask"
			              v-model="unicast_netmask"
			              textBy="id"
			              :options="netmaskOptions"
			              :error="!!unicastNetmaskErrors.length"
			              :error-messages="unicastNetmaskErrors"
			              noClear
			            />
						<va-input
							v-model="unicast_gateway"
							pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
							type="text"
							label="Unicast Gateway*"
							placeholder="Enter Unicast Gateway"
							:error="!!unicastGatewayErrors.length"
							:error-messages="unicastGatewayErrors"
						/>
						<va-checkbox
			            	label="Multicast IP"
			            	v-if="isTypeInput"
			            	v-model="is_multicast"/>
			            <div v-if="isTypeInput && is_multicast">
							<div class="form-group" v-for="(input,k) in inputs" :key="k">
						      	<input class="margin_right margin_bottom" type="text" pattern="/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/" v-model="input.name" name="name" placeholder="eg:(1.3.2.1)" required>
								<va-button class="remove"  id="align" @click.prevent="removeInput(k)">x</va-button>
							</div><br>
							<va-button @click.prevent="addNewInput()">Add</va-button>
				        </div>
						<div class="d-flex justify--center mt-3">
							<va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateInterfaceSetting()">Update</va-button>
							<va-button type="submit" class="my-0" @click.prevent="list()">Cancel</va-button>
						</div>
					</form>
				</div>
			</va-card>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
Vue.use(vueResource)

export default {
	name: 'interface',
	beforeCreate () {
		const loader = Vue.$loading.show({ width: 40, height: 40 })
		this.$http.get(config.menu.host + '/interface/').then(response => {
			loader.hide()
			this.interfaceData = response.body
			this.isshowForm = false
			this.isshowgrid = true
			this.$http.post(config.menu.host+'/interface/refresh/interface/').then(response => {
				this.getAllInterface()
			})
		}, error => {
			loader.hide()
			if (error && error.body) {
				Vue.notify({ text: error.body, type: 'error' })
			}
		})
	},
	data () {
		return {
			term: null,
			perPage: '10',
			perPageOptions: ['4', '6', '10', '20'],
			isshowgrid: false,
			isshowForm: false,
			isCreate: false,
			isUpdate: false,
			showRemoveModal: false,
			isTypeInput: false,
            isTypeOutput: false,
            is_multicast: false,
			unicast_netmask: '',
			type:'',
			title: '',
			unicast_ip:'',
			unicast_gateway:'',
			interfaceData: [],
			unicastipErrors: [],
			unicastGatewayErrors: [],
			unicastNetmaskErrors: [],
			interfaceNameErrors:[],
			interfaceTypeErrors: [],
			interfaceTypeOptions: [{id:'Input'}],
			inputs: [{"index":1,"name":''}],
			netmaskOptions : [
				{id:'8'},{id:'9'},{id:'10'},{id:'11'},{id:'12'},{id:'13'},{id:'14'},{id:'15'},{id:'16'},{id:'17'},{id:'18'},{id:'19'},{id:'20'},{id:'21'},{id:'22'},{id:'23'},{id:'24'},{id:'25'},{id:'26'},{id:'27'},{id:'28'},{id:'29'},{id:'30'},{id:'31'},{id:'32'}
			],
		}
	},
	computed: {
		formReady () {
			return !this.interfaceTypeErrors.length && !this.unicastGatewayErrors.length && !this.unicastipErrors.length && !this.unicastNetmaskErrors.length
		},
		fields () {
			return [{
	          name: 'sno',
	          title: 'S.NO',
	          width: '6%',
        	},{
				name: '__slot:marker',
				width: '30px',
				dataClass: 'text-center',
			},
			{
				name: 'interface_name',
				title: 'Interface Name',
			},
			{
				name: 'unicast_ip',
				title: 'Unicast IP',
			},
			{
				name: 'type',
				title: 'Type',
			},
			{
				name: '__slot:status',
				title: 'status',
			},
			{
				name: '__slot:actions',
				dataClass: 'text-right',
			}]
		},
		filteredData () {
			return search(this.term, this.interfaceData)
		},
	},
	methods: {
		checkInterfaceType(){
   			this.isTypeInput = false
            this.isTypeOutput = false
            if (this.type.id === 'Input') {
                this.isTypeInput = true
                this.is_multicast = false
                this.inputs = [{"index":1,"name":''}];
            } else if (this.type === 'Output') {
               	this.isTypeOutpu.idt = true
                this.output_ip = ''
                this.starting_port = ''
                this.inputs = [{"index":1,"name":''}];
            } else {
                // DO nothing
            }
		},
		removeInput (index) {
           	this.inputs.splice( index, 1);
            if ( this.inputs.length == 0 || this.inputs.length == null){
                this.inputs.push({"index":1});
            }
        },
		addNewInput () {
            var newInputNo = this.inputs.length+1;
            this.inputs.push({'index':newInputNo});
        },
		getAllInterface () {
			this.$http.get(config.menu.host + '/interface/interface/all').then(response => {
				let index = 0
				this.interfaceData = response.body.map(function (item) {
				  item.id = index++
				  item.sno = index
				  item.is_existing = true
				  item.checkbox_value = false
				  return item
				})
				this.isshowgrid = true
			})
		},
		updateInterfaceSetting () {
			this.unicastGatewayErrors = this.unicast_gateway ? [] : ['Unicast Gateway is required']
			this.unicastNetmaskErrors = this.unicast_netmask ? [] : ['Unicast Netmask is required']
			this.unicastipErrors = this.unicast_ip ? [] : ['Unicast IP is required']
			this.interfaceTypeErrors = this.type ? [] : ['Interface Type is required']

			if (!this.formReady) {
				return Vue.notify({ text: "Please fill the form correctly", type: 'error' })
			}
			var outputIP = []
            var multicast = []
            if(this.is_multicast){
                for (var i = 0; i < this.inputs.length; i++) {
                	if((this.inputs[i].name == "") || (this.inputs[i].name == undefined)){
                		return Vue.notify({text: "Please fill the form correctly",type:'error'})
                	}else{
    	                multicast.push(this.inputs[i].name)
                	}
                }
            }
            var payload = {
                type:this.type.id,
                interface_name:this.interface_name,
                unicast_ip:this.unicast_ip,
                unicast_netmask:this.unicast_netmask.id,
                unicast_gateway:this.unicast_gateway,
                output_ip:this.output_ip,
                starting_port:this.starting_port,
                transcoder_name :this.transcoder_name,
                is_multicast :this.is_multicast,
                bridge_status :this.bridge_status
            }

            if(this.is_multicast){
                payload['multicast_ip'] = multicast
            }else{
                payload['multicast_ip'] = []
            }
            const loader = Vue.$loading.show({ width: 40, height: 40 })
            this.$http.put(config.menu.host + '/interface/' + this.networksetting_id, payload).then(responseData => {
            	loader.hide()
				if (responseData && responseData.body) {
					Vue.notify({ text: responseData.body, type: 'success' })
				}
				this.list()
			}, errResponse => {
				loader.hide()
				if (errResponse && errResponse.body) {
					Vue.notify({ text: errResponse.body, type: 'error' })
				}
			})
		},
		edit (row) {
			this.title = 'Update Interface'
			if(row.type === "Output"){
                this.isTypeOutput= true  
            }else if(row.type === "Input"){
                this.isTypeInput= true
            }


            if(row.is_multicast){
                row.is_multicast = true
                // var parse=entity.multicast_ip
                // if(typeof(entity.multicast_ip)=='string'){
                //     parse = JSON.parse(entity.multicast_ip)
                // }
                // parse = parse.map(function(item){
                //     return {'name':item}
                // })
                // $scope.inputs = parse
                var temp = row.networkinfos.map(function (item) {
                    return { name: item.multicast_ip }
                })
                this.inputs = temp

            }else{
                this.inputs= [
                    {
                        name: '',
                    },
                ]
            }

			this.interface_name = row.interface_name
			this.type = row.type
			this.unicast_ip = row.unicast_ip
			this.unicast_netmask = row.unicast_netmask
			this.unicast_gateway = row.unicast_gateway
			this.is_multicast = row.is_multicast
			this.output_ip = row.output_ip
			this.starting_port = row.starting_port
			this.speed = row.speed
			this.networkinfos = row.networkinfos
			this.networksetting_id = row.networksetting_id
			this.isshowgrid = false
			this.isshowForm = true
			this.isCreate = false
			this.isUpdate = true
		},
		list () {
			this.isshowForm = false
			this.isshowgrid = true
			this.getAllInterface()
		},
		search: debounce(function (term) {
			this.term = term
		}, 400),
	},
}
</script>

<style lang="scss">
  .badge_status {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    margin-top: 15px;
  }

  .badge {
    padding: 8px 0.5rem;
  }

</style>
